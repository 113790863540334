<template>
  <div class="bureau-list" v-show="loadCompleted">
    <template v-if="bureaus">
      <HistoricoAlteracoes
        class="history-changes"
        :listaConsultas="listaConsultas"
        :listaBureaus="this.bureaus.ListaBureaus"
      />

      <div class="bureau-container bureaus-query">
        <div class="bureau-container-title">
          Dados de integração
        </div>

        <div class="bureau-container-cards">
          <div
            v-for="(consulta, index) in listaConsultas"
            :key="index"
            class="bureau-container-card"
            @click="goToBureau(consulta.TipoConsultaId)"
          >
            <p class="bureau-container-card-title">
              {{ consulta.NomeConsulta }}
            </p>
            <p class="bureau-container-card-description">
              {{ consulta.DescricaoConsulta }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
        <span class="material-icons text-6xl">description</span>
        <p>Dados não encontrados</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HistoricoAlteracoes from "@/views/parametrizacao/components/bureau/HistoricoAlteracoes.vue";

export default {
  name: "param-bureaus-consulta",
  components: {
    HistoricoAlteracoes
  },
  data() {
    return {
      loadCompleted: false
    };
  },
  computed: {
    ...mapGetters("parametrizacao-module", [
      "bureaus",
      "listaConsultas",
      "listaBureaus"
    ])
  },
  methods: {
    ...mapActions("parametrizacao-module", ["getBureaus"]),

    async init() {
      try {
        await this.getBureaus();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      } finally {
        this.loadCompleted = true;
      }
    },

    goToBureau(tipoConsultaId) {
      this.$router.push({
        name: "parametrizacao-bureaus",
        params: { tipoConsultaId: tipoConsultaId }
      });
    }
  },
  async created() {
    this.$appInsights.trackPageView({
      name: "param-bureaus-consulta",
      url: window.location.href
    });

    await this.$onpoint.loading(async () => {
      return await this.init();
    });
  }
};
</script>

<style scoped lang="scss">
.bureau-list {
  position: relative;
}

.history-changes {
  position: absolute;
  right: 0;
  margin-top: calc(-1.5rem - 36px);
}

.bureau-container {
  background: #fff;
  border-radius: 16px;
  margin: 0;
  padding: 42px 24px 35px;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);

  &-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 24px;
    padding: 0;
    color: #4b5563;
  }

  &-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 24px;
    border: 1px solid rgba(229, 231, 235, 1);
    border-radius: 8px;
    background-color: rgba(249, 250, 251, 1);
    color: rgba(75, 85, 99, 1);
    cursor: pointer;
    margin-bottom: 16px;

    &:hover {
      background-color: rgba(249, 250, 251, 0.6);
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    &-title {
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &-description {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}
</style>
