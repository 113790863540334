var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loadCompleted,
          expression: "loadCompleted"
        }
      ],
      staticClass: "bureau-list"
    },
    [
      _vm.bureaus
        ? [
            _c("HistoricoAlteracoes", {
              staticClass: "history-changes",
              attrs: {
                listaConsultas: _vm.listaConsultas,
                listaBureaus: this.bureaus.ListaBureaus
              }
            }),
            _c("div", { staticClass: "bureau-container bureaus-query" }, [
              _c("div", { staticClass: "bureau-container-title" }, [
                _vm._v("\n        Dados de integração\n      ")
              ]),
              _c(
                "div",
                { staticClass: "bureau-container-cards" },
                _vm._l(_vm.listaConsultas, function(consulta, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "bureau-container-card",
                      on: {
                        click: function($event) {
                          return _vm.goToBureau(consulta.TipoConsultaId)
                        }
                      }
                    },
                    [
                      _c("p", { staticClass: "bureau-container-card-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(consulta.NomeConsulta) +
                            "\n          "
                        )
                      ]),
                      _c(
                        "p",
                        { staticClass: "bureau-container-card-description" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(consulta.DescricaoConsulta) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]
        : [_vm._m(0)]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32"
      },
      [
        _c("span", { staticClass: "material-icons text-6xl" }, [
          _vm._v("description")
        ]),
        _c("p", [_vm._v("Dados não encontrados")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }