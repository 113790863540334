var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "btn-view-changes",
              attrs: { round: "" },
              on: {
                click: function($event) {
                  return _vm.lidarComHistoricoAlteracoes()
                }
              }
            },
            [
              _c("i", {
                staticClass: "onpoint-clock-counter-clockwise icon-font pr-2"
              }),
              _vm._v("\n      Histórico de alterações\n    ")
            ]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "history-change",
          attrs: {
            visible: _vm.popupHistoricoAlteracoes,
            "close-on-click-modal": "",
            "modal-append-to-body": "",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.popupHistoricoAlteracoes = $event
            }
          }
        },
        [
          _c("div", { staticClass: "pop-up-content" }, [
            _c("div", { staticClass: "pop-up-content-header" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/icons/ccg-icons/history-icon-ccg.svg"),
                  alt: "Ícone de excluir representante",
                  width: "24",
                  height: "24"
                }
              }),
              _c("h3", [_vm._v("Histórico de alterações")])
            ]),
            _c("div", { staticClass: "pop-up-content-comparation" }, [
              _c("div", { staticClass: "pop-up-content-comparation-header" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-data" },
                  [_vm._v("\n            Dados\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider"
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-before" },
                  [_vm._v("\n            Antes\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider"
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-after" },
                  [_vm._v("\n            Depois\n          ")]
                )
              ]),
              _c(
                "ul",
                { staticClass: "pop-up-content-comparation-items" },
                _vm._l(_vm.historicoAlteracoes, function(alteracao, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "pop-up-content-comparation-item"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pop-up-content-comparation-item-data" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Data:\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.formatarData(alteracao.Data)) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Usuário:\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.NomeUsuario) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Local:\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.formatarLocal(alteracao)) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Ação:\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        alteracao.Acao
                                          ? _vm.formatarAcao(alteracao.Acao)
                                          : "-"
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c("div", {
                        staticClass: "pop-up-content-comparation-item-divider"
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-before"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Bureau:\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        alteracao.Antes.BureauId
                                          ? _vm.formatarAlteracao(
                                              alteracao.Antes.BureauId
                                            )
                                          : "-"
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pop-up-content-comparation-item-divider-arrow"
                        },
                        [_c("i", { staticClass: "el-icon-right" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-after"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Bureau:\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        alteracao.Depois.BureauId
                                          ? _vm.formatarAlteracao(
                                              alteracao.Depois.BureauId
                                            )
                                          : "-"
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer pop-up-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.popupHistoricoAlteracoes = false
                    }
                  }
                },
                [_vm._v("Fechar")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }